
export const s3Config = {

    buckets: {
    'eu-west-2':'xxxx'
    },
    defaultRegion:'eu-west-2'
  };
  

