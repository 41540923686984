import {
  Component,
  OnInit,
  HostListener
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  AuthService
} from '../../service';
import {
  SignupForm
} from '../../types';
import Swal1 from 'sweetalert';

@Component({
  selector: 'app-password',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class ForgotPasswordComponent {

  username: string;
  submissionError: string;
  submitted = false;
  formErrors: SignupForm = {};

  constructor(private authService: AuthService, private router: Router) {}

@HostListener('window:resize', ['$event'])
onResize(event) {

  if  (window.innerWidth < 600)
  {
    this.router.navigate(['notfound']);
  }

  
 
}




  forgotPassword($event) {
    this.submitted = true;
    // Disable default submission.
    $event.preventDefault();
    this.authService.forgotPassword(this.username,
      (err, statusCode) => {
        this.submitted = false;
        if (statusCode === AuthService.statusCodes.verificationCodeRequired) {
          this.router.navigate(['reset-password']);
        } else if (statusCode === AuthService.statusCodes.noSuchUser) {

          this.submissionError = 'Email is not valid';


          setTimeout(() => {
            this.submissionError = ""
          }, 6000);



        } else if (statusCode === AuthService.statusCodes.unknownError) {

          this.submissionError = err.message;
        }
      });
  }



  ngOnInit() {





   
 /*   $('#inputUsername').on('input', function() {

      var input = $(this);
      var re = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$/;
      var is_email = re.test(input.val());
      if (is_email) {
        $('#inputUsername').css("border", "2px solid #34a955");
      } else {

        $('#inputUsername').css("border", "2px solid #e71e63");

      }

    });*/



  }



}