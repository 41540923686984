import { Component, Input, OnDestroy,OnInit,HostListener } from '@angular/core';
import {
  FileObject,
  ContainerEvents,
  FileObjectStatus,
} from '../types';
import { Router } from '@angular/router';
import { UploadService } from '../service';
import { UploadContainerComponent} from '../../upload/component';
import { Subscription } from 'rxjs';
import { S3 } from 'aws-sdk';
import {
  IdleSessionTimeout
} from "idle-session-timeout";
import * as $ from 'jquery';
import Swal1 from 'sweetalert';





 
 

@Component({
  moduleId: module.id,
  selector: 'app-file-upload',
  templateUrl: 'component.html',
  styleUrls: ['component.scss']
})
export class FileUploadComponent implements OnDestroy {
  @Input() fileObject: FileObject;
  @Input() oddRow: boolean;
  FileObjectStatus = FileObjectStatus;
  progress = 0;
  speed = 0;
  uploadError: string;
  containerEventSubscription: Subscription;
  uploadHandle: any;

  constructor(private uploadService: UploadService, private router: Router,private uploadcontainercomponent:UploadContainerComponent) {
    this.containerEventSubscription = uploadService.uploadContrainerEvent$.subscribe(
      containerEvent => this.handleContainerEvent(containerEvent)
    );
  }

  private handleContainerEvent(containerEvent: ContainerEvents) {
    if (containerEvent === ContainerEvents.Upload) {
      return this.fileObject.status === FileObjectStatus.NotStarted && this.upload();
    } else if (containerEvent === ContainerEvents.Cancel) {
      return this.fileObject.status === FileObjectStatus.Uploading && this.cancel();
    } else if (containerEvent === ContainerEvents.Delete) {
      return this.clear();
    }
  }

  upload() {
    this.fileObject.status = FileObjectStatus.Uploading;
    this.uploadError = undefined;
    this.progress = 0;
    this.uploadHandle = this.uploadService.upload(this.fileObject.file, this.handleS3UploadProgress());
  }

  private handleS3UploadProgress() {
    return (error: Error, progress: number, speed: number) => {
      if (error) {
        this.progress = 0;
        this.speed = 0;
        this.uploadError = error.message;
        this.fileObject.status = FileObjectStatus.Failed;
         Swal1("Upload not successfull",
          { icon: "error",
            dangerMode: true,
          }as any).then((upload) => {
         this.uploadcontainercomponent.clearAll();
         

         

  } );
      } else {
        
        this.progress = progress || this.progress;
        this.speed = speed || this.speed;
        if (this.progress === 100) {
            this.fileObject.status = FileObjectStatus.Uploaded;

       Swal1("Upload successfull",
        { icon: "success",}as any)
       .then((upload) => {
     
         setTimeout(() => { this.uploadcontainercomponent.ngOnInit(); }, 1000);
         setTimeout(() => { this.uploadcontainercomponent.clearAll(); }, 500);
         setTimeout(() => { this.fileObject.status = FileObjectStatus.Deleted;}, 500); 
         setTimeout(() => { this.uploadService.publishFileUploadEvent(this.fileObject);}, 500); 

  } );


        }
      }
    };
  }


 uploadAll()  {
      this.uploadService.publishUploadContainerEvent(ContainerEvents.Upload);

      }   

  cancel() {
    if (this.fileObject.status === FileObjectStatus.Uploading) {
      this.fileObject.status = FileObjectStatus.Canceled;
      this.uploadService.cancel(this.uploadHandle);
    }
  }

  clear() {
    if (this.fileObject.status !== FileObjectStatus.Uploading) {
      this.fileObject.status = FileObjectStatus.Deleted;
      this.uploadService.publishFileUploadEvent(this.fileObject);
    }
  }



@HostListener('window:resize', ['$event'])
onResize(event) {

  if  (window.innerWidth < 600)
  {
    this.router.navigate(['notfound']);
  }

  
 
}








   ngOnInit() {

  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.containerEventSubscription.unsubscribe();
  }
}
