
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from './service';
import {S3} from 'aws-sdk'
import { FileSizeUtil, MonthUtil } from '../../utils';


@Component({
  moduleId: module.id,
  selector: 'app-loading',
  templateUrl: 'component.html',
  styleUrls: ['component.scss']
})
export class LoadingComponent implements OnInit {
	
constructor(
    private router: Router,
    private loadingService: LoadingService) {
  }



ngOnInit(): void

   {

           
       //  setTimeout(() => {this.loadingService.bucketCheck(); }, 500);
   	   // this.loadingService.bucketCheck()


         
  }




















}
