
import { Component, OnInit ,HostListener,ViewChild,Injectable } from '@angular/core';
import { User } from '../auth/types';
import { S3Factory } from '../../utils';
import { s3Config } from '../../config';
import { DataService } from '../../config/s3service';
import { HttpClient } from '@angular/common/http';
import { Http,Response } from '@angular/http';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import * as download from 'downloadjs';


@Injectable()
export class DownLoadService {

  private signedInUser: User;
  private region: string;
  private signedUrlExpireSeconds = 60 / 2;
  public fileName: string;
  public message:string;

  constructor(private http:Http, private httpClient:HttpClient, private data:DataService) {
    this.region = s3Config.defaultRegion || 'eu-west-2';
  }

  setSignedInUser(user: User) {
    this.signedInUser = user;
  }

  // Upload status updates

  setRegion(region: string) {
    this.region = region;
  }


 downloadFile(key) {
    const url = S3Factory.getS3(this.region).getSignedUrl('getObject', {
      Bucket:this.message,
      Key:'Download/'+key,
      Expires:this.signedUrlExpireSeconds
    });

    this.httpClient.get(url)
    .toPromise()
    .then(response => {
    })
    .catch(Error);
    download(url)

   
}


  listFiles() {
      this.data.cmpidsourcecurrent.subscribe(message => this.message = message);
      return S3Factory.getS3(this.region).listObjectsV2({
      Bucket: this.message,
      Delimiter: '/',
      Prefix:'Download/'
    }).promise();
  }

  getUrl(key: string) {
    return S3Factory.getS3(this.region).getSignedUrl('getObject', {
      Bucket:this.message,
      Key:key,
      Expires:this.signedUrlExpireSeconds
    });
  }

}


