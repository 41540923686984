import { Component, OnInit,Input,HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UploadService } from './service';
import { ContainerEvents, FileObject, FileObjectStatus } from './types';
import { AuthService, User } from '../auth';
import { URLUtil } from '../../utils';
import { FileSizeUtil, MonthUtil } from '../../utils';
import { FileUpload } from './file-upload';
import Swal1 from 'sweetalert'
import {IdleSessionTimeout} from "idle-session-timeout";

// import * as $ from 'jquery';
import * as moment from 'moment';
var session = new IdleSessionTimeout(60 * 60 * 1000);


/*
 *
 * Contrainer for all uploads.
 */
@Component({
  moduleId: module.id,
  selector: 'app-upload',
  templateUrl: 'component.html',
  styleUrls: ['component.scss']
  
})

export class UploadContainerComponent implements OnInit {
  files: FileObject[] = [];
  signedInUser: User;
  uploadStarted = false;

  @Input() fileUpload: FileUpload;
 
  constructor(private authService: AuthService,private router: Router,private uploadService: UploadService) {
    uploadService.fileUploadEvent$.subscribe(fileObject => this.handleFileUploadEvent(fileObject));
   
  }
  rows = [
  ];
  columns = [
    { prop: 'name'},
    { name: 'Size'},
    { name: 'timemod'},
    { name: 'Date' }

  ];

  private handleFileUploadEvent(fileObject: FileObject) {
    if (fileObject.status === FileObjectStatus.Deleted) {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i] === fileObject) {
          this.files.splice(i, 1);
        }
      }
    }
  }
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files.length) {
      for (let i = 0; i < fileInput.target.files.length; i++) {
        const fileObject = new FileObject(fileInput.target.files[i]);
        this.files.push(fileObject);
      }
    }
    fileInput.target.value = null;
     setTimeout(() => {  this.uploadService.publishUploadContainerEvent(ContainerEvents.Upload); }, 500);

    this.ngOnInit();

   
  }
      uploadAll()  {
      this.uploadService.publishUploadContainerEvent(ContainerEvents.Upload);

      }      

      cancelAll() {
      this.uploadService.publishUploadContainerEvent(ContainerEvents.Cancel);
      }

     clearAll()   {
     this.uploadService.publishUploadContainerEvent(ContainerEvents.Delete);
     }  

  ngOnInit() {

    session.onTimeOut = () => {

 this.authService.signout();
};


   session.start();


    this.authService.getCurrentUser((err, user: User) => {
           this.signedInUser = user;
           this.uploadService.setSignedInUser(this.signedInUser);
      if (!this.signedInUser || !this.signedInUser.signedIn) {
           
              this.authService.signout();
        
           
             setTimeout(() => {  location.reload(); }, 500);
          

        return;
   } else {

           this.setRegion();
           this.uploadService.listFiles().then((response) => {
           this.rows = response.Contents.map((data)=>{
           const row : any = {};
           row.url = this.uploadService.getUrl(data.Key);
           row.key = data.Key.split('/').pop();
           row.date = data.LastModified;
          // row.date = moment(data.LastModified).format('DD/MM/YYYY  HH:mm')
           row.size = FileSizeUtil.transform(data.Size);          
     
         return row;
        })
        }).catch((error) => {
        }) ;        
      }


    });




  }




  download(key) {
   this.uploadService.downloadFile(key);

   }


   
  delete(file) {

Swal1({
  title: "Are you sure?",
  text: "Once deleted, you will not be able to recover this file",
  icon: "warning",
  buttons: true,
  dangerMode: true,
} as any)

.then((willDelete) => {
  if (willDelete) {
     this.uploadService.deleteFile(file);
    Swal1("Your file has been deleted", {
      icon: "success",
    }) .then((willDelete) => {this.ngOnInit();  });   
  } 
});
  
  }


@HostListener('window:resize', ['$event'])
onResize(event) {

  if  (window.innerWidth < 540)
  {
    this.router.navigate(['notfound']);
  }

  
 
}


  setRegion() {
    const queryParams = this.router.routerState.snapshot.root.queryParams;
    if (queryParams && queryParams.region) {
      this.uploadService.setRegion(queryParams.region);
    }
  }
}
        