import { Component, OnInit,Input,HostListener } from '@angular/core';
import { Router } from '@angular/router';
 
@Component({
  selector: 'app-notfound',
  templateUrl: './404.html',
  styleUrls: ['./component.scss']
})
 
export class NotfoundComponent implements OnInit {


	constructor(private router: Router){

	}


@HostListener('window:resize', ['$event'])
onResize(event) {

  if  (window.innerWidth < 600)
  {
    this.router.navigate(['notfound']);
  }

  else if (window.innerWidth >= 601) {

     this.router.navigate(['signin']);

  }
 
}


    ngOnInit() { 



}

 }