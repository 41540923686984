import {
  Component,
  OnInit,
  HostListener
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  AuthService
} from '../../service';
import {
  SignupForm
} from '../../types';
import Swal1 from 'sweetalert';
import * as $ from 'jquery';



@Component({
  selector: 'app-password',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],

})
export class ResetPasswordComponent implements OnInit {

  verificationCode: string;
  newPassword: string;
  confirmPassword: string;
  submissionError: string;
  submitted = false;
  formErrors: SignupForm = {};
  statusMessage: string;
  statusClass: string;
  isSuccessful = false;

  constructor(private authService: AuthService, private router: Router) {}



  private validateNewPassword() {
    let isValid = true;
    if (this.confirmPassword !== this.newPassword) {
      isValid = false;
      this.formErrors.confirmPassword = 'Confirm password should match new password.';
      setTimeout(() => {
        this.submissionError = ""
      }, 3000);
    }

    return isValid;
  }


@HostListener('window:resize', ['$event'])
onResize(event) {

  if  (window.innerWidth < 600)
  {
    this.router.navigate(['notfound']);
  }

  
 
}




  resetPassword($event) {

    if (!this.validateNewPassword()) {

      return;
    }
    this.submitted = true;
    // Disable default submission.
    $event.preventDefault();

    this.authService.confirmPassword(this.verificationCode, this.newPassword,
      (err, statusCode) => {

        this.submitted = false;
        if (statusCode === AuthService.statusCodes.incompletedSigninData) {
          this.router.navigate(['forgot-password']);
        } else if (statusCode === AuthService.statusCodes.success) {
          Swal1('Password change is successful. You will be redirected to signin page within 5 seconds', {
            icon: "success",
          });
          

          setTimeout(() => {
            this.authService.signout();
          }, 4000);

        } 



      



 else if (statusCode === AuthService.statusCodes.unknownError) 
        { 

          
          
           this.submissionError =" an error has occured";

         
        }

















      });
  }





  ngOnInit() {

   


/*

 $('#inputVerificationCode').on('input', function() {
        var input = $(this);
        var re = /^[0-9+]{6}/;
        var is_phone = re.test(input.val());
        if (is_phone) {

            $('#inputVerificationCode').css("border", "2px solid #34a955");

        } else {
           
            $('#inputVerificationCode').css("border", "2px solid #e71e63");
        }
    });






    $('#inputPassword').on('input', function() {
        var input = $(this);
        var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}/;
        var is_phone = re.test(input.val());
        if (is_phone) {
            $('#inputPassword').css("border", "2px solid #34a955");
       

        } else {
            $('#inputPassword').css("border", "2px solid #e71e63");
        }
    });






    $('#inputconfirmsPassword').on('input', function() {
        var input = $(this);
        var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}/;
        var is_phone = re.test(input.val());
        if (is_phone) {
            $('#inputconfirmsPassword').css("border", "2px solid #34a955");
       

        } else {
            $('#inputconfirmsPassword').css("border", "2px solid #e71e63");
        }
    });
*/




    $('#inputPassword').keyup(function() {

      var pass = ( < HTMLInputElement > document.getElementById('inputPassword')).value;


      //validate the length

      if (pass.length < 12) {
        $('#length').removeClass('valid').addClass('invalid');
      } else {
        $('#length').removeClass('invalid').addClass('valid');
      }


      //valid special character
      if (pass.match(/[!@#$%^&*()_\-\[\]{};':"\\|,.<>\/?]+/)) {
        $('#character').removeClass('invalid').addClass('valid');
      } else {
        $('#character').removeClass('valid').addClass('invalid');
      }



      //validate capital letter
      if (pass.match(/[A-Z]/)) {
        $('#capital').removeClass('invalid').addClass('valid');
      } else {
        $('#capital').removeClass('valid').addClass('invalid');
      }



      //validate number
      if (pass.match(/\d/)) {
        $('#number').removeClass('invalid').addClass('valid');
      } else {
        $('#number').removeClass('valid').addClass('invalid');
      }
    }).focus(function() {
      $('#pswd_info').show();
    }).blur(function() {
      $('#pswd_info').hide();
    });



    $('#inputconfirmPassword').keyup(function() {

      var pass = ( < HTMLInputElement > document.getElementById('inputPassword')).value;


      //validate the length

      if (pass.length < 12) {
        $('#length').removeClass('valid').addClass('invalid');
      } else {
        $('#length').removeClass('invalid').addClass('valid');
      }


      //valid special character
      if (pass.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)) {
        $('#character').removeClass('invalid').addClass('valid');
      } else {
        $('#character').removeClass('valid').addClass('invalid');
      }



      //validate capital letter
      if (pass.match(/[A-Z]/)) {
        $('#capital').removeClass('invalid').addClass('valid');
      } else {
        $('#capital').removeClass('valid').addClass('invalid');
      }



      //validate number
      if (pass.match(/\d/)) {
        $('#number').removeClass('invalid').addClass('valid');
      } else {
        $('#number').removeClass('valid').addClass('invalid');
      }
    }).focus(function() {
      $('#pswd_info').show();
    }).blur(function() {
      $('#pswd_info').hide();
    });



  }


















}