import { Router, NavigationEnd } from '@angular/router';
import { Component } from '@angular/core';
import {} from  'googleanalytics';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'app';

  constructor(public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
       
      }
    });
  }
}
