import {Component,OnInit} from '@angular/core';
import { AuthService } from '../service';
import { Router } from '@angular/router';
import Swal1 from 'sweetalert'

@Component({
  template: ``
})
export class SignoutComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {


Swal1({
  title: "Are you sure",
  text: "You want to leave?",
  icon: "warning",
  buttons: true,
  dangerMode: true,
}as any)
.then((signout) => {
  if (signout) {
     this.authService.signout();
   
  } else {
        this.router.navigate(['home']);  
      }

});





   
  }
} 
