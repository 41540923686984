import { Injectable } from '@angular/core';
import { Subject ,Observable } from 'rxjs';
import { User } from '../auth/types';
import { ContainerEvents, FileObject } from './types';
import { S3 } from 'aws-sdk';
import { S3Factory } from '../../utils';
import { s3Config } from '../../config';
import { DataService } from '../../config/s3service';
import { FileUpload } from './file-upload';
import { HttpClient } from '@angular/common/http';
import { Http,Response } from '@angular/http';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import Swal1 from 'sweetalert';
import * as download from 'downloadjs';

@Injectable()
export class UploadService {
   
  // Observable string sources
  private uploadContainerEventSource = new Subject<ContainerEvents>();
  private fileUploadEventSource = new Subject<FileObject>();

  // Observable string streams
  uploadContrainerEvent$ = this.uploadContainerEventSource.asObservable();
  fileUploadEvent$ = this.fileUploadEventSource.asObservable();
  private signedInUser: User;
  private region: string;
  private signedUrlExpireSeconds = 60 / 2;
  public  message:string;
  

  constructor(private http:Http, private httpClient:HttpClient,private data:DataService) {
    this.region = s3Config.defaultRegion || 'eu-west-2';
  }

  setSignedInUser(user: User) {
    this.signedInUser = user;
  }

  // Upload status updates
  publishUploadContainerEvent(event: ContainerEvents) {
    this.uploadContainerEventSource.next(event);
  }

  publishFileUploadEvent(file: FileObject) {
    this.fileUploadEventSource.next(file);
  }

  setRegion(region: string) {
    this.region = region;
  }





  

  public preparePutObjectRequest(file: File, region: string): S3.Types.PutObjectRequest {
    const now = new Date();
   this.data.cmpidsourcecurrent.subscribe(message => this.message = message);
   //this.data.cmpidsourcecurrent.subscribe(message => console.log(message+'.upload'));
    const obj = {
      Key: 'Upload/'+[file.name],
      Bucket: this.message,
      Body: file,
      ContentType: file.type, 
    };
    return obj;
  }







  upload(file: File, progressCallback: (error: Error, progress: number, speed: number) => void, region?: string) {
    if (!this.signedInUser) {
      progressCallback(new Error('User not signed in'), undefined, undefined);
      return;
    }
    region = region || this.region;
    const s3Upload = S3Factory.getS3(region).upload(this.preparePutObjectRequest(file, region));
    s3Upload.on('httpUploadProgress', this.handleS3UploadProgress(progressCallback));
    s3Upload.send(this.handleS3UploadComplete(progressCallback));
    
    return s3Upload;

  }


  private handleS3UploadProgress
    (progressCallback: (error: Error, progress: number, speed: number) => void) {
    let uploadStartTime = new Date().getTime();
    let uploadedBytes = 0;
    return (progressEvent: S3.ManagedUpload.Progress) => {
      const currentTime = new Date().getTime();
      const timeElapsedInSeconds = (currentTime - uploadStartTime) / 1000;
      if (timeElapsedInSeconds > 0) {
        const speed = (progressEvent.loaded - uploadedBytes) / timeElapsedInSeconds;
        const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        progressCallback(undefined, progress, speed);
        uploadStartTime = currentTime;
        uploadedBytes = progressEvent.loaded;

      }
    };
  }


  

downloadFile(key) {
    const url = S3Factory.getS3(this.region).getSignedUrl('getObject', {
      Bucket:this.message,
      Key:'Upload/'+key,
      Expires:this.signedUrlExpireSeconds
    });

    this.httpClient.get(url)
    .toPromise()
    .then(response => { 
    })
    .catch(Error);
    download(url)
  }



listFiles() {
     this.data.cmpidsourcecurrent.subscribe(message => this.message = message);
      return  S3Factory.getS3(this.region).listObjectsV2(
        { Bucket: this.message, Delimiter: '', Prefix: 'Upload/'}).promise();}



deleteFile(file:File) {
    this.data.cmpidsourcecurrent.subscribe(message => this.message = message);
    const param ={  Bucket:this.message, Key:'Upload/'+ file };
          S3Factory.getS3(this.region).deleteObject( param, function (err, data) {if (err) {
        return;
      }
     
    });

}


getUrl(key: string) {
    return S3Factory.getS3(this.region).getSignedUrl('getObject', {
      Bucket:this.message,
      Key:key,
      Expires:this.signedUrlExpireSeconds
    });

  }


  private handleS3UploadComplete(
    progressCallback: (error: Error, progress: number, speed: number) => void) {
    return (error: Error, data: S3.ManagedUpload.SendData) => {
      if (error) {
        progressCallback(error, undefined, undefined);
      } else {
        progressCallback(error, 100, undefined);
      }
    };
  }

  cancel(s3Upload: S3.ManagedUpload) {
    s3Upload.abort();
  }
}
