import { Component , OnInit,HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../service';
import { SignupForm } from '../../types';
import Swal1 from 'sweetalert';
// import * as $ from 'jquery';
// import * as jsdom from 'jsdom';


@Component({
  selector: 'app-password',
  templateUrl: './component.html',
  styleUrls: ['./component.scss']
})
export class FirstTimePasswordComponent implements OnInit {

  newPassword: string;
  confirmPassword: string;
  submissionError: string;
  submitted = false;
  statusMessage: string;
  statusClass: string;
  formErrors: SignupForm = {};

  constructor(private authService: AuthService, private router: Router) { }
  private validateNewPassword() {
    let isValid = true;
    if (this.confirmPassword !== this.newPassword) {
      isValid = false;
    //  this.formErrors.confirmPassword = 'Confirm password should match new password.';
       Swal1('Passwords do not match', { icon: "error",});      
    }
    return isValid;
  }
  updatePassword(event) {
    // Disable default submission.
    event.preventDefault();
    if (!this.validateNewPassword()) {
      return;
    }
    this.submitted = true;
    this.formErrors = {};
    this.authService.authenticate({
      newPassword: this.newPassword
    },
      (err, statusCode) => {
       this.submitted = false;
        if (statusCode === AuthService.statusCodes.incompletedSigninData) {
          this.router.navigate(['']);
        } else if (statusCode === AuthService.statusCodes.success) {
          Swal1('Password change is successful. You will be redirected to signin page within 5 seconds', {
            icon: "success",
          });
          

          setTimeout(() => {
            this.authService.signout();
          }, 4000);

        } 
          else if (statusCode === AuthService.statusCodes.unknownError) {
          this.submissionError = err.message;
        }
      });
 
  }

@HostListener('window:resize', ['$event'])
onResize(event) {

  if  (window.innerWidth < 600)
  {
    this.router.navigate(['notfound']);
  }
 
}

 ngOnInit() {

  $('#inputPassword').keyup(function() {
  var pass = (<HTMLInputElement>document.getElementById('inputPassword')).value;
  //validate the length
 if ( pass.length < 12 ) {
  $('#length').removeClass('valid').addClass('invalid');
 } else {
 $('#length').removeClass('invalid').addClass('valid');
}
//valid special character
if ( pass.match(/[!@#$%^&*()_\-\[\]{};':"\\|,.<>\/?]+/) ) {
     $('#character').removeClass('invalid').addClass('valid');
} else {
     $('#character').removeClass('valid').addClass('invalid');
}
//validate capital letter
if ( pass.match(/[A-Z]/) ) {
    $('#capital').removeClass('invalid').addClass('valid');
} else {
    $('#capital').removeClass('valid').addClass('invalid');
}
//validate number
if ( pass.match(/\d/) ) {
    $('#number').removeClass('invalid').addClass('valid');
} else {
    $('#number').removeClass('valid').addClass('invalid');
}
}).focus(function() {
    $('#pswd_info').show();
}).blur(function() {
    $('#pswd_info').hide();
});


}











}
