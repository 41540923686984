import { Component,OnInit,ViewChild,Input,Output,EventEmitter,Injectable} from '@angular/core';
import {BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class DataService {

  cmpidsource = new BehaviorSubject('');

  cmpidsourcecurrent = this.cmpidsource.asObservable();
  constructor() { }
  changeMessage(message: string) {
    this.cmpidsource.next(message)


  }

}

