import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule} from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppComponent } from './app.component';
import { HashLocationStrategy, Location, LocationStrategy } from '@angular/common';
import { AppRoutesModule } from './app-routes.module';
import { AuthService,FirstTimePasswordComponent,ForgotPasswordComponent,ResetPasswordComponent,SigninComponent,SignoutComponent} from './auth';
import { HomeComponent} from './home';
import { S3 } from 'aws-sdk';
import { HttpModule } from "@angular/http";
import { TermsComponent } from './terms/terms.component';
import { DownloadComponent, DownLoadService } from './download';
import { LoadingComponent } from './loading/component';
import { UploadContainerComponent,FileUploadComponent,UploadService} from './upload';
import { FileSizePipe } from '../utils';
import { HeaderComponent} from './header/component';
import { HeaderComponent2} from './header2/component';
import { FooterComponent} from './footer/component';
import { NotfoundComponent} from './404/notfound';
import { DataService} from '../config/s3service';
import { LoadingService} from './loading/service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { NgxPasswordToggleModule } from 'ngx-password-toggle';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';





@NgModule({
  declarations: [
    AppComponent,
    FirstTimePasswordComponent,
    ForgotPasswordComponent,
    HomeComponent,
    LoadingComponent,
    ResetPasswordComponent,
    SigninComponent,
    SignoutComponent,
    TermsComponent,
    DownloadComponent,
    FileSizePipe,
    FileUploadComponent,
    UploadContainerComponent,
    HeaderComponent,
    HeaderComponent2,
    FooterComponent,
    NotfoundComponent,

  ],
  exports:[
    SigninComponent,
    UploadContainerComponent,

  ],
  imports: [
    AppRoutesModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    NgxDatatableModule,
    BrowserAnimationsModule,
    ShowHidePasswordModule,
    NgxPasswordToggleModule,
    NgProgressModule.withConfig({
      spinnerPosition: 'right',
      color: '#003a99'}),
    NgProgressHttpModule,


  ],
  providers: [
    AuthService,
    SigninComponent,
    UploadContainerComponent,
    DownLoadService,
    UploadService,
    DataService,
    LoadingService,
    Location,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
