import { Injectable } from '@angular/core';
import { User } from '../auth/types';
import { S3Factory } from '../../utils';
import { s3Config } from '../../config';
import { AuthService } from '../auth/service';
import { DataService } from '../../config/s3service';
import { CognitoIdentityCredentials, config as AWSConfig, AWSError,S3 } from 'aws-sdk';
import jwtDecode  from 'jwt-decode';
import { cognitoConfig } from '../../config';
import { CognitoUserPool,AuthenticationDetails, CognitoUser, ICognitoUserAttributeData,CognitoUserAttribute,CognitoUserSession} from 'amazon-cognito-identity-js';
import * as aws from 'aws-sdk';



@Injectable()
export class LoadingService {


  private signedInUser: User;
  private region: string;
  private signedUrlExpireSeconds = 60 * 5;
  private static userPoolLoginKey = `cognito-idp.${cognitoConfig.userPool.region}.amazonaws.com/${cognitoConfig.userPool.UserPoolId}`;
  private userPool = new CognitoUserPool(cognitoConfig.userPool);

  public message:string;

  constructor(private data:DataService ,private authservice:AuthService) {
    this.region = s3Config.defaultRegion || 'eu-west-2';
  }

  setSignedInUser(user: User) {
    this.signedInUser = user;
  }

  
  setRegion(region: string) {
    this.region = region;
  }



 bucketCheck() {

  


  }


}
 










