import { Component,OnInit,ViewChild,Input,Output,EventEmitter,Injectable,HostListener} from '@angular/core';
import { sequence,trigger,stagger,animate,style,group,query ,transition,keyframes,animateChild, } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { AuthService } from '../service';
import { Router,NavigationExtras } from '@angular/router';
import { SignupForm } from '../types';
import { DataService } from "../../../config/s3service";





@Component({
  selector: 'app-signin',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],

 animations: [
   trigger('forgottenstate', [
    transition('* <=> *', [

  
])

 ])

   ]



})
export class SigninComponent implements OnInit{
 public companyid:string;
 public panelshow: boolean = true;
  message:string;
  username: string;
  password: string;
  submissionError:string;
  submitted = false;
  formErrors: SignupForm = {};
  statusMessage: string;  
  

  constructor(private authService: AuthService, private router: Router,private data: DataService) {}


 
  signin($event) {
 
       setTimeout(() => { this.submitted = true; }, 100);
       setTimeout(() => { this.panelshow = !this.panelshow; }, 100);
   
   
    // Disable default submission.
     $event.preventDefault();

     
      this.data.changeMessage(this.companyid);

     
      this.authService.authenticate({
      username: this.username,
      password: this.password,
    
    },
      (err, statusCode) => {
        setTimeout(() => { this.submitted = false; }, 100);
        setTimeout(() => { this.panelshow = true; }, 100);
         
        if (statusCode === AuthService.statusCodes.newPasswordRequired) {
          this.router.navigate(['first-time-password']);
        } else if (statusCode === AuthService.statusCodes.signedIn) {
          this.authService.handleRedirect();
          return;
        } else if (statusCode === AuthService.statusCodes.noSuchUser) {
          this.submissionError = 'Email or password is not valid.';

           setTimeout(() => {
            this.submissionError = ""
          }, 6000);

        } else if (statusCode === AuthService.statusCodes.unknownError) 



        {
 
          if(err.message ==="PreAuthentication failed with error Multiple failed attempts, you have been locked out. Try again in 1 minute.") {

          this.submissionError = "Multiple failed attempts… you have been locked out. Try again in 1 minute."
           setTimeout(() => {
            this.submissionError = ""
          }, 6000);


        }

         else  if (err.message ==="Missing required parameter SMS_MFA_CODE")
         {
                this.submissionError = "Incorrect one time access code. Please try again"
                 setTimeout(() => {
            this.submissionError = ""
          }, 6000);


         }  
          

          else {this.submissionError = err.message 

             setTimeout(() => {
            this.submissionError = ""
          }, 6000);




          }
          
        }

       

      });  

  }



@HostListener('window:resize', ['$event'])
onResize(event) {

  if  (window.innerWidth < 600)
  {
    this.router.navigate(['notfound']);
  }

  
 
}






  ngOnInit() {





    this.authService.setPreviousAppParams(this.router.routerState.snapshot.root.queryParams);
    this.authService.getCurrentUser((err, currentSignedInUser) => {
      if (currentSignedInUser && currentSignedInUser.signedIn) {
        this.authService.handleRedirect();
       
      }
    });


  }
}


