import {
  Component,
  OnInit,
  Injectable,
  HostListener
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  AuthService,
  User
} from '../auth';
import {
  URLUtil
} from '../../utils';
import {
  DataService
} from '../../config/s3service';
import {
  Subject,
  Observable
} from 'rxjs';
import {
  IdleSessionTimeout
} from "idle-session-timeout";
// import * as $ from 'jquery';


var session = new IdleSessionTimeout(60 * 60 * 1000);

/**
 * Container for security scans.
 */
@Component({
  moduleId: module.id,
  selector: 'app-home',
  templateUrl: 'component.html',
  styleUrls: ['component.scss']
})

@Injectable()
export class HomeComponent implements OnInit {
  signedInUser: User;
  baseUrl: string;
  message: string;
  vault: string;


  constructor(private authService: AuthService, private router: Router, private data: DataService) {

    this.data.cmpidsourcecurrent.subscribe(message => this.message = message);
    this.vault = this.message;

     session.onTimeOut = () => {this.authService.signout();}


  }



  @HostListener('window:resize', ['$event'])
  onResize(event) {

    if (window.innerWidth < 600) {
      this.router.navigate(['notfound']);
    }


  }


 

  ngOnInit() {

    var uploadImage = ( < HTMLInputElement > document.getElementById('uploadB'));
    var downloadImage = ( < HTMLInputElement > document.getElementById('downloadB'));

    uploadImage.onmouseover = function() {
      $('#hexSolid').css('background-image', 'url(./assets/img/Upload-white2.png)');
      $('#hexSolid').css('background-color', '#00263D;');

    }

    uploadImage.onmouseout = function() {

      $('#hexSolid').css('background-image', 'url(./assets/img/cloudcomp.png)');
      $('#hexSolid').css('background-color', '#00263D');
    }



    downloadImage.onmouseover = function() {
      $('#hexSolid').css('background-image', 'url(./assets/img/Download-white2.png)');
      $('#hexSolid').css('background-color', '#00263D;');

    }

    downloadImage.onmouseout = function() {
      $('#hexSolid').css('background-image', 'url(./assets/img/cloudcomp.png)');
      $('#hexSolid').css('background-color', '#00263D');

    }
    session.start();




    this.authService.getCurrentUser((err, user: User) => {
      this.signedInUser = user;
      this.authService.setSignedInUser(this.signedInUser);

      if (!this.signedInUser || !this.signedInUser.signedIn) {


        this.authService.signout();
        setTimeout(() => {
          location.reload();
        }, 500);

        return;
      } else {
        this.baseUrl = URLUtil.getBaseUrl();
      }

    });

  }





}