import { Component, OnInit ,HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DownLoadService } from './service';
import { AuthService, User } from '../auth';
import { S3 } from 'aws-sdk'
import { FileSizeUtil, MonthUtil } from '../../utils';
import { IdleSessionTimeout } from "idle-session-timeout";
import * as moment from 'moment';
var session = new IdleSessionTimeout(60 * 60 * 1000);

@Component({
  moduleId: module.id,
  selector: 'app-download',
  templateUrl: 'component.html',
  styleUrls: ['component.scss']
})
export class DownloadComponent implements OnInit {
  signedInUser: any;

  rows = [
  ];
  columns = [
    { prop: 'name'},
    { name: 'Size'},
    { name: 'Date'}

  ];

  constructor(private authService: AuthService,
    private router: Router,
    private downloadService: DownLoadService) {
  }
  

@HostListener('window:resize', ['$event'])
onResize(event) {

  if  (window.innerWidth < 540)
  {
    this.router.navigate(['notfound']);
  }

 
}



  ngOnInit(): void

   {




session.onTimeOut=() => {

 this.authService.signout();
};
 



   session.start();




    this.authService.getCurrentUser((err, user: User) => {
      this.signedInUser = user;
      this.downloadService.setSignedInUser(this.signedInUser);
      if (!this.signedInUser || !this.signedInUser.signedIn) {

       this.authService.signout();
        this.router.navigate(['signin']);

             setTimeout(() => {  location.reload(); }, 500);
     
        // this.authService.redirectToSignin(this.router.routerState.snapshot.root.queryParams);
        return;
      } else {
        this.downloadService.listFiles().then((response) => {
        this.rows = response.Contents.map((data)=>{
           const row : any = {};
           row.url = this.downloadService.getUrl(data.Key);
           row.key = data.Key.split('/').pop();
           row.date = data.LastModified;
           row.size = FileSizeUtil.transform(data.Size);
           return row;
        })
        }).catch((error) => {
        }) ;        
      }



    });





  }

  download(key) {
   this.downloadService.downloadFile(key);
    session.reset();
   }




}
